/* eslint-disable */

export const bcndOrigin = 'https://salespoint.backend.warehouseghana.com';
export const viewOrigin = 'https://salespoint.view.warehouseghana.com';
export const wc_endPoint = 'https://warehouseghana.com/wp-json/wc/v3';
export const WC_SECRET='cs_53a1642ce18765ff5483802a18119b968ed4d6aa';
export const WC_KEY='ck_683f3c18239a530bfae59d58ae5453e6ba77a004';

export const WC_ED_SECRET='cs_f1a0b087ef93fa4b432a1b2eb3ffc4975ac04e90';
export const WC_ED_KEY='ck_9e223867527154b70ebaf3a50a242d2e3593ba59';

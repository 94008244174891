 import logo from '../assets/images/logo.webp';

const Logo = () => {

  return (
    <>
      <img src={logo} alt="Warehouse Ghana Logo" width="50" />
    </>
  );
};

export default Logo;

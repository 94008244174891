// import { createStore } from 'redux';
import { legacy_createStore as createStore} from 'redux'
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer);
// const persister = 'Free';

export default store;
